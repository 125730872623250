import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import InshaPage from "views/InshaBanglesPage/InshaPage.js";
import InshaLogin from "views/InshaBanglesPage/Section/InshaLogin.js";
import InshaRegister from "views/InshaBanglesPage/Section/InshaRegister.js";
import InshaProductList from "views/InshaBanglesPage/Section/InshaProductList.js";
import InshaProduct from "views/InshaBanglesPage/Section/InshaProduct.js";
import InshaSizeMMList from "views/InshaBanglesPage/Section/InshaSizeMMList.js";
import EditProfile from "views/InshaBanglesPage/Profile/EditProfile.js";
import AddAddress from "views/InshaBanglesPage/Profile/AddAddress.js";
import UpdateAddress from "views/InshaBanglesPage/Profile/UpdateAddress.js";
import GeneratePassword from "views/InshaBanglesPage/Profile/GeneratePassword.js";
import Cart from "views/InshaBanglesPage/Cart/Cart.js";
import OrderViewPrint from "views/InshaBanglesPage/Cart/OrderViewPrint";
import AddCart from "views/InshaBanglesPage/Cart/AddCart.js";
import CartShippingAddress from "views/InshaBanglesPage/Cart/CartShippingAddress";
import CartView from "views/InshaBanglesPage/Cart/CartView";
import MyOrder from "views/InshaBanglesPage/Cart/MyOrder";
import OrderView from "views/InshaBanglesPage/Cart/OrderView";
import PaymentRecieved from "views/InshaBanglesPage/Cart/PaymentRecieved";
import InshaBanglesSizeGuide from "views/InshaBanglesPage/Section/InshaBanglesSizeGuide";
import ErrPage from "views/InshaBanglesPage/Section/ErrPage.js"
import OfflineInshaProductList from "views/InshaBanglesPage/Section/OfflineInshaProductList";
import MakingCharge from "views/InshaBanglesPage/Section/MakingCharge";
import InshaContacts from "views/InshaBanglesPage/Section/InshaContacts";
import CareerField from "views/InshaBanglesPage/Section/CareerField";
import PrivacyPolicy from "views/InshaBanglesPage/Section/PrivacyPolicy";
import ReturnPolicy from "views/InshaBanglesPage/Section/ReturnPolicy";
import ReturnAmount from "views/InshaBanglesPage/Section/ReturnAmount";
import RefundPolicy from "views/InshaBanglesPage/Section/RefundPolicy";
import TermCondition from "views/InshaBanglesPage/Section/Term&Condition";
import TopBangles from "views/InshaBanglesPage/Section/TopBangles";
import License from "views/InshaBanglesPage/Section/License";
import ProductStock from "views/InshaBanglesPage/Section/ProductStock.js"
import OrderStock from "views/InshaBanglesPage/Section/OrderStock";
import ImageGallery from "views/InshaBanglesPage/Section/ImageGallery.js";
// import Payment from "views/InshaBanglesPage/Cart/Payment";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/error-page" component={ErrPage} />
      <Route path="/insha-bangles-size-guide" component={InshaBanglesSizeGuide} />
      <Route path="/cart" component={Cart} />
      <Route path="/order-view-print" component={OrderViewPrint} />
      <Route path="/add-cart" component={AddCart} />
      <Route path="/cart-shipping-address" component={CartShippingAddress} />
      <Route path="/cart-view" component={CartView} />
      <Route path="/my-order" component={MyOrder} />
      <Route path="/order-view" component={OrderView} />
      <Route path="/payment-recieved" component={PaymentRecieved} />
      <Route path="/edit-password" component={GeneratePassword} />
      <Route path="/add-address" component={AddAddress} />
      <Route path="/edit-update-address" component={UpdateAddress} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/size-mm-list" component={InshaSizeMMList} />
      <Route path="/insha-product" component={InshaProduct} />
      <Route path="/product-list" component={InshaProductList} />
      <Route path="/offline-product-list" component={OfflineInshaProductList} />
      <Route path="/insha-register" component={InshaRegister} />
      <Route path="/making-charge" component={MakingCharge} />
      <Route path="/contact-us" component={InshaContacts} />
      <Route path="/insha-login" component={InshaLogin} /> 
      <Route path="/careers" component={CareerField} />
      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/return-policy" component={ReturnPolicy} />
      <Route path="/return" component={ReturnAmount} />
      <Route path="/refund-policy" component={RefundPolicy} />
      <Route path="/terms-and-conditions" component={TermCondition} />
      <Route path="/top-bangles-manufacturers-in-india" component={TopBangles} />
      <Route path="/license" component={License} />
      <Route path="/todays-deal" component={ProductStock} />
      <Route path="/order-stock" component={OrderStock} />
      <Route path="/image-gallery" component={ImageGallery} />
      {/* <Route path="/payment" component={Payment} /> */}
      <Route path="/" component={InshaPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
